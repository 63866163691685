$disclaimerText: .8rem; $introText: 1rem; $text:.940rem; $headerText: 1.070rem; 
$contentSpace: 3rem; $contentSpaceInline: 1.5rem; $contentSpaceInput: .3rem;
$mobilePadding: 1rem; $windowPadding: 24rem; $modalFormPadding: 4rem;
$pageHeightWithoutNav: calc(100vh - 4.6rem);
$borderRadiusSmall: 5px; $borderRadiusMedium: 1rem;
$smallBoxShadow:  0 .15rem 1.75rem 0 rgba(58,59,69,.15);

$bgColor: white; $bgColorSecondary: rgba(229, 231, 234, 0.537); $textColor: #333447;
$mainColor: #2c2b2a; $fadedMainColor: #2c2b2a9f; $secondColor: #066226; $lightSecondColor: #0c8436;
$footerColor: rgba(229, 231, 234);
$red: #ff0033; $darkRed: #5f1515; $error: #c32958; $errorBackground: #f3d4dd; $grey: #d7d9db; $modalBackground: #70707ec7; $closeButton: #333448;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
//check out poppins-semibold, poppins, sans-serif

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
    background-color: $bgColor;
    color: $textColor;
    font-family: 'Montserrat', sans-serif;
}

html, body {
    margin: 0;
    padding: 0 ;
}

#root {
    display: flex;
    flex-direction: column;

    .hidden {
        display: none;
    }

    a {
        text-decoration: none;
    }

    > .indeterminate-progress {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

main {
    width: 100%;
}

section {
    padding: $contentSpace $mobilePadding;

    h1, h2 {
        margin: 0;
        margin-bottom: $contentSpaceInline;
    }

    h1 { font-size: 2.3rem; }
}

section:nth-child(even) {
    background-color: $bgColorSecondary;
}

section.twin {
    display: flex;
    flex-direction: column;

    > div {
        order: 2;
        width: 100%;

        img {
            max-width: 100%;
        }
    }

    div:nth-child(2) {
        order: 1;
        padding: 0;
        margin-bottom: $contentSpace;
    }

    button {
        margin: auto;
    }
}

.hero {
    position: relative;
    min-height: 40vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 0;
    margin: 0;

    .ts-carousel_content {
        height: 100%;
    }

    .hero-content {
        background-color: $fadedMainColor;
        border-radius: none;
        height: 100%;
        width: 100%;
        padding: 1rem;

        h1 {
            font-size: 1.5rem;
        }

        button {
            margin: $contentSpaceInline auto;
        }

        a {
            color: white;
            font-weight: bold;
        }

        strong.open {
            color: #4bcd78;
        }

        strong.close {
            color: $darkRed;
        }
    }

    hr {
        color: white;
    }

    p {
        font-size: $introText;
        margin-bottom: 0;
    }
}

.choose-us {
    display: flex;
    flex-direction: column;

    > div {
        width: 100%;
        padding: 0;
    }
}

.specailist {
    svg {
        position: relative;
        bottom: -.2rem;
        height: auto;
        width: 1.3rem;
    }
}

.service {
    position: relative;
    color: white;
    width: 100%;
    margin-bottom: $contentSpace;
    
    &:first-child {
        margin-right: 0;
    }

    &:last-child {
        margin-left: 0;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        height: 100%;
        width: 100%;
        background-color: $fadedMainColor;
    }

    h3 {
        position: absolute;
    }

    img {
        max-width: 100%;
    }
}

section.process {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            min-width: 20rem;
            border-top: solid 8px $secondColor;
            border-radius: $borderRadiusMedium;
            padding: $contentSpaceInline;
            margin: $contentSpaceInline 0;
            background-color: $mainColor;
            color: white;
        }

        svg {
            fill: $mainColor;
        }
    }
}

section.gallery {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    ul:first-child {
        li {
            cursor: pointer;
            font-weight: bold;
            font-size: .8rem;
            background-color: $secondColor;
            color: white;
            padding: $contentSpaceInline;
            transition: background .2s ease-in-out;

            &:hover {
                background-color: black;
            }
        }

        .active {
            background-color: black;
        }
    }

    .filter-container {
        background-color: $mainColor;
    }

    .filter-item {
        height: auto;
        width: 100%;
        padding: $contentSpaceInline;

        img {
            height: auto;
            max-width: 100%;
        }
    }
}

svg {
    fill: rgb(108, 108, 108);
    height: 3rem;
    width: auto;
}

.ts-carousel {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;

    .ts-carousel_content {
        z-index: 99;
    }

    .ts-carousel_slide {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: .6;
        transition: opacity .2s ease-in-out;
    }

    .ts-carousel_slide.active {
        opacity: 1;
    }

    .ts-carousel_back, .ts-carousel_forward {
        z-index: 10;
        cursor: pointer;
        display: none;
        position: absolute;
        transition: fill ease-out .1s;
        background-color: black;
        border-radius: 50%;
        padding: .5rem;

        svg {
            fill: white;
            transition: fill .2s ease-in;

            &:hover {
                fill: $mainColor;
            }
        }

        &:active {
            fill: white;
        }

        &:hover {
            fill: white;
        }
    }

    .ts-carousel_back {
        left: calc($contentSpaceInline * 3);
    }

    .ts-carousel_forward {
        right: calc($contentSpaceInline * 3);
    }

    .ts-carousel_position {
        z-index: 10;
        cursor: pointer;
        display: none;
        position: absolute;
        bottom: 1rem;
        background-color: black;
        border-radius: $borderRadiusMedium;
        padding: .5rem 2rem;

        svg {
            height: .75rem;
            margin: $contentSpaceInput;
            fill: white;
            transition: fill .2s ease-in-out;
        }

        svg.active {
            fill: $secondColor;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.container {
    .highlight {
        .highlight-header {
            text-align: center;
        }

        h4 {
            margin-top: $contentSpaceInline * 2;
            text-align: left;
        }

        img {
            max-width: 100%;
        }
    }
}

.form {
    text-align: center;
    border-radius: .8rem;

    .intro {
        line-height: 1.47;
        font-size: $introText;
        text-align: left;
    }

    label {
        text-align: left;
    }

    hr {
        margin: $contentSpaceInline 0;
        border: solid $grey .6px;
    }

    .button-container {
        display: flex;
        justify-content: space-between;

        button {
            width: 45%;
        }
    }

    .main-button {
        margin-bottom: $contentSpaceInput;
    }

    .multi-input {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .input-container {
            width: 100%;
        }
    }

    .input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: $contentSpaceInput*2 0;

        label {
            margin: $contentSpaceInput 0;
            font-size: $headerText;
            font-weight: 600;
        }

        label.required {
            &::after {
                content: "*";
                color: red;
            }
        }

        textarea {
            height: 7rem;
        }

        select {
            height: 2.8rem;
            font-size: $text;
            padding: .4rem .8rem;
        }

        input {
            min-height: 2rem;
            border: none;
        }

        input.error-field {
            border: solid $error 1px;
            color: $error;
        }

        .error-info {
            position: absolute;
            bottom: -$contentSpaceInput*4;
            text-align: left;
            color: $error;
        }

        textarea, input, select {
            font-family: 'Open Sans', sans-serif;
            font-size: $text;
            padding: .6rem;
            border-radius: 0;
            border-radius: $borderRadiusSmall;
            border: solid $textColor 1px;
        }

        .link {
            color: green;
            margin-top: $contentSpaceInline;
            text-align: left;
            font-weight: bold;
        }
        
        .progress-1 {
            accent-color: red;
        }

        .progress-2 {
            accent-color: initial;
        }

        progress {
            position: absolute;
            bottom: -$contentSpaceInput*4;
            transition: opacity .5s ease-in-out;
        }

        .error {
            position: absolute;
            left: 0;
            bottom: -2rem;
            font-size: .8rem;
            color: $error;
        }
    }

    .input-container.error-field {
        textarea, input {
            border: none;
            border: solid $error 1px;
        }
    }

    .error-output {
        display: flex;
        justify-content: center;
        background-color: $errorBackground;
        margin-top: 0;
        margin-bottom: $contentSpaceInline;
        padding: 1.2rem .8rem;
        font-weight: bold;
        border-radius: .4rem;
        opacity: 1;
        transition: opacity .3s ease-in-out;

        .exclamation {
            padding-right: .8rem;
        }

        .close {
            margin-left: auto;
            cursor: pointer;
        }

        img {
            height: 1.25rem;
            width: auto;
        }
    }
}

.main-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    width: 12rem;
    border: none;
    padding: 0;
    color: white;
    background-color: $mainColor;
    border-radius: .2rem;
    font-weight: 600;
    font-size: $introText;
    text-decoration: none;

    &:active {
        background-color: $mainColor;
    }

    &:hover {
        background-color: $mainColor;
    }

    &:focus {
        box-shadow: 0px 0px 8px 1px #dddddd;
        background-color: $mainColor;
    }

    &:disabled {
        background-color: grey;
    }

    svg {
        max-height: 2rem;
        margin-left: $contentSpaceInput;
        margin-right: $contentSpaceInput;
    }
}

section.map-container {
    display: flex;
    flex-direction: column;

    > div {
        width: 100%;
    }

    .mapouter {
        min-height: 20rem;
        text-align: right;
    }
}

.outro {
    h2 {
        text-align: center;
        font-size: 2.5rem;
    }

    .main-button {
        svg {
            transform: rotate(45deg);
            transition: transform ease-in-out .2s;
        }

        &:hover {
            svg {
                transform: rotate(0deg);
            }
        }
    }
}

footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: $contentSpaceInline;
    background-color: $footerColor;
    width: 100%;

    .footer-logo {
        img {
            height: 3rem;
            width: auto;
        }

        svg {
            height: 3rem;
            width: auto;
            margin-left: -$contentSpaceInput;
            margin-bottom: -$contentSpaceInput;
        }
    }

    .footer-navs {
        display: flex;
        justify-content: space-between;

        .footer-nav {
            width: 50%;

            h2 {
                margin-top: 0;
                font-size: 1.25rem;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                font-size: .9rem;
            }
        }
    }

    .socials {
        margin-top: $contentSpaceInline * 2;

        ul {
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0;

            svg {
                height: 2rem;
                width: auto;
                fill: black;

                &:hover {
                    fill: $mainColor;
                }
            }
        }
    }
}

.second-color {
    color: $secondColor;
}

.text-center {
    text-align: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-evenly {
    display: flex;
    justify-content: space-evenly;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-end-column {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.m-top-lg {
    margin-top: $contentSpace;
}

.column {
    flex-direction: column;
}

.copy {
    margin-top: $contentSpaceInput;
    width: 100%;
    text-align: center;
}

// DESKTOP
@media only screen and (min-width:80rem) {
    #root {
        display: flex;
        align-items: center;
    }

    section {
        max-width: 160rem;
        padding: $contentSpace 20%;
    }

    section.hero {
        .ts-carousel_content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: unset;
            width: unset;
        }

        .hero-content {
            border-radius: $borderRadiusMedium;
            padding: $contentSpace;
            height: unset;
            width: unset;

            h1 {
                font-size: 3rem;
            }
        }
    }

    section.twin {
        flex-direction: row;
    
        > div {
            width: 50%;
            order: 1;
        }
    
        div:nth-child(2) {
            padding: $contentSpace;
        }
    }

    section.gallery {
        .filter-item {
            width: calc(100% / 3);
        }
    }

    section.map-container {
        flex-direction: row;

        > div {
            width: 50%;
        }
    }

    .choose-us {
        flex-direction: row;
        flex-wrap: wrap;
    
        > div {
            width: calc(100% / 3);
            padding: $contentSpaceInline;
        }

        > div:first-child {
            padding-left: 0;
        }

        > div:last-child {
            padding-right: 0;
        }
    }

    .services {
        display: flex;
    }

    .service {
        width: calc(100%/3);
        
        &:first-child {
            margin-right: $contentSpace;
        }
    
        &:last-child {
            margin-left: $contentSpace;
        }
    }

    .ts-carousel {
        .ts-carousel_back, .ts-carousel_forward {
            display: block;
        }

        .ts-carousel_position {
            display: block;
        }
    }

    .form {
        // max-width: 60rem;
        width: 100%;

        .multi-input {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .input-container {
                width: 48%;
            }
        }
    }

    .highlights { margin-top: $contentSpaceInline * 2; }

    .highlight {
        display: flex;
        margin-bottom: $contentSpaceInline * 2;

        .highlight-header {
            h4 {
                margin-top: 0;
            }

            img {
                height: auto;
                width: 40rem;
            }
        }
    }

    .highlight:nth-child(odd) {
        .highlight-messages {
            padding-left: 20%;
        }
    }

    .highlight:nth-child(even) {
        .highlight-header {
            order: 2;
        }
        
        .highlight-messages {
            order: 1;
            padding-right: 20%;
        }
    }

    .spotlight {
        max-width: 20rem;
        margin-bottom: 0;
    }

    .outro {
        h2 {
            text-align: center;
            font-size: 3rem;
        }

        .main-button {
            svg {
                transform: rotate(45deg);
                transition: transform ease-in-out .2s;
            }
    
            &:hover {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
    }

    footer {
        padding: $contentSpaceInline * 2 $contentSpaceInline * 4;
        margin: 0 auto;

        .footer-logo {
            img {
                height: 3rem;
                width: auto;
            }
        }

        .footer-navs {
            justify-content: initial;
            
            .footer-nav {
                width: initial;
                margin-right: $contentSpaceInline;
            }
        }

        .socials {
            ul {
                justify-content: initial;

                li {
                    margin-right: $contentSpaceInline;
                }
            }
        }
    }
}